.error-color, #address-box-helper-text, #address-name-helper-text {
  color: #ff4842 !important;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus  {
  -webkit-text-fill-color: white important;
  background-clip: content-box !important;
  -webkit-box-shadow: 0 0 0 1000px black inset !important;

}

#address-box-helper-text  {
  background-color: #121619;
  margin: 0;
  padding-right: 14px;
  padding-bottom: 0;
  padding-left: 14px;
  padding-top: 8px;
}

.Mui-disabled.pay-now-btn {
  /* background-color: rgba(145, 158, 171, 0.8) !important; */
  background-color: gray !important;
  
}

.gift-voucher-accordion .MuiAccordionSummary-content {
  margin: 0px !important;
}
.gift-voucher-input {
  width: 160px !important;
}

/* #mobile-cart-dialog .MuiDialog-container.css-idnud {
  overflow: visible !important;
} */

.input-helper-text {
  line-height: 1.5;
  font-size: 0.75rem;
  font-family: 'Poppins';
  font-weight: 400;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  margin-top: 8px;
}


.helper-text-error {
  line-height: 1.5;
  font-size: 0.75rem;
  font-family: 'Poppins';
  font-weight: 400;
  text-align: left;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  margin-top: 8px;
}


/* Customer portal */
html {
  background-color: #fff;
}
header {
  position: relative !important;
  padding-right: 0px !important;
}

header > div {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

body {
  /* padding-left: 5px !important;
  padding-right: 5px !important; */
  /* padding: 3px; */
  /* padding-right: 3px !important; */
  /* background-color: #000 !important; */
  /* rgba(0,0,0,1) */
  /* overflow: unset !important;  */
  font-family: Poppins !important;
}

h1,h2,h3,h4,h5,h6,div,span,p,button {
  font-family: Poppins !important;
}

main {
  background-color: #000 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

/* h1,h2,h3,h4,h5,h6,p {
  color: #ffffff;
} */
.css-dp5fpk-MuiTypography-root, .MuiTypography-root {
  color: #ffffff;
}

.MuiOutlinedInput-root {
  background-color: #ffffff;
}

.mobile-sub-categories > .MuiOutlinedInput-root {
  background-color: #000 !important;
}

.mobile-sub-categories {
  float: right;
  margin-top: 10px !important;
  margin-right: 10px !important;
}
/* a.MuiLink-root{
  color: #F6B327 !important;
} */

.black-text-color {
  color: #000 !important;
}


.pc-btn, .pc-btn:hover {
  color: #000 !important;
  background-color: #F6B327 !important;

}

.landing-page-main-text {
  text-align: center;
  font: normal normal bold 40px/66px Poppins;
  letter-spacing: 0px;
  font-size: 40px !important;
 }


.use-my-location {
  text-align: left;
  font: normal normal medium 18px/29px Poppins;
  letter-spacing: 0px;
  color: white;
  opacity: 1 !important;
  font-size: 18px !important;
}

#root .SnackbarContent-root.SnackbarItem-variantSuccess, #root .SnackbarContent-root.SnackbarItem-variantError, #root .SnackbarContent-root.SnackbarItem-variantWarning, #root .SnackbarContent-root.SnackbarItem-variantInfo {
  color: rgb(33, 43, 54) !important;
  background-color: rgb(255, 255, 255) !important;
}

.whats-new-text {
  font: normal normal bold 40px/66px Poppins;
  letter-spacing: 0px;
  color: #F6B327;
  opacity: 1;
  font-size: 40px !important;
}

.previous-selected-store {
  font: normal normal bold 25px/40px Poppins;
  letter-spacing: 0px;
  color: #F6B327;
  opacity: 1;
  font-size: 25px !important;
}

.site-announcement {
  color: rgba(0,0,0,1);
  font-size: 18px !important;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
}

.nav-item.active {
  background-color: #331f00;
}

.nav-item:last-child {
  border-bottom: solid darkgrey 0.001px;
}

.orange-text-color {
  color: #F6B327 !important;
}

.red-text-color {
  color: #ff4842 !important
}


.white-text-color {
  color: #ffffff !important;
}

.red-text-color  {
  color: red !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}
.pizza-club-snackbar, .SnackbarContainer-root, #confirm-modal, .MuiAutocomplete-popper {
  z-index: 999999999 !important;
}

.orange-background-color {
  background-color: #F6B327 !important;
}

.MuiCircularProgress-root .MuiCircularProgress-svg {
  color: #F6B327 !important;
}

.white-background-color {
  background-color: #ffffff !important;
  color: #000000 !important;
}

#sort-by-product ul {
  background-color: #ffffff !important;
  color: #000000 !important;
  border-radius: 8px;
}

.black-background-color {
  background-color: #000 !important;
}

.black-text-color {
  color: #000 !important;
}

.store-search-icon:hover {
  background-color: rgba(246, 179, 39, 1) !important;
}

.landing-banner, .header-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.MuiTabs-indicator {
  display: none;
}

.pizza-club-max-brand-btn {
  background-color: #ff4842 !important;
  color: #ffffff !important;
}
.pizza-club-max-brand-text {
  color: #ff4842 !important;
}
.circle {
  display: inline-flex;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  /* padding: 10px; */
  background: #000;
  border: 2px solid #F6B327 ;
  color: #000;
  text-align: center;
  font: 32px Arial, sans-serif;
}

.store-announce-heading {
  margin-right:  2px;
  color: #000 !important;
}

.category-active, .sub-category-active {
  background-color: #ffffff !important;
  border-radius: 30px;
  color: #000 !important;
}

.product-image {
  transition: transform .2s;
  position: relative;
  padding: 9px 6px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  
  border-radius: 0px !important;
  margin-bottom: 5px;
}

.cart-banner {
  transition: transform .2s;
  position: relative;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  
  border-radius: 10px;
  margin-bottom: 5px;
}

.hnh-product-image {
  transition: transform .2s;
  position: relative;
  padding: 9px 6px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  margin-bottom: 5px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.hnh-product-selected {
  border: 1px solid #F6B327 !important;
  border-radius: 13px 

} 

.hnh-product-details {
  padding-top: 3px;
  text-align: left;
  font: normal normal bold 14px/23px Poppins;
  letter-spacing: 0px;
  font-size: 14px !important;
  color: #FFFFFF;
  opacity: 1;
  font-family: 'Poppins' !important;
}

.hnh-product-custom-btn, .hnh-product-custom-btn:hover {
  border: 1px solid #F6B327 !important;
  border-radius: 18px !important;
  opacity: 1;
  background-color: #000000 !important;
}

.product-height {
  height: 150px;
}
.mobile-product-height {
  height: 120px;
}

.product-contains-property-height {
  height: 170px;
}

.best-seller {
  color: #ffffff;
  border-radius: 2px !important;
  background: #84C438 0% 0% no-repeat padding-box !important;
  font: normal normal medium 12px/19px Poppins !important;
  letter-spacing: 0px;
  text-align: left;
  font-family: Poppins !important;
  font-size: 14px;
}

.best-seller > span {
  color: #ffffff;
  border-radius: 2px;
  background: #84C438 0% 0% no-repeat padding-box !important;
  font: normal normal medium 12px/19px Poppins !important;
  letter-spacing: 0px;
  text-align: left;
  font-family: Poppins !important;
  font-size: 14px;
} 

.product-favouite-icon:hover {
  background: #000000 0% 0% no-repeat padding-box;
  background-color: #000000 !important;
}

.veg-status > svg {
  background-color: #ffffff;
}

.veg-status:hover {
  background-color: transparent !important;
}

.recc-toppings {
  color: #ffffff !important;
  /* border-radius: 2px; */
  background: #F5F5F5 0% 0% no-repeat padding-box !important;
  font: normal normal normal 12px/13px Poppins !important;
  letter-spacing: 0px;
  border-radius: 8px !important;
  font-family: 'Poppins';
  font-size: 10px;
  height: 25px;
  opacity: 0.15;
}

.recc-toppings > span {
  color: #000 !important;
  /* border-radius: 2px; */
  font: normal normal normal 12px/13px Poppins !important;
  letter-spacing: 0px;
  font-family: Poppins !important;
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  opacity: 1;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-description-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.deal-description-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.product-toppings-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}


.sensitivity, .default-toppings {
  color: #ffffff !important;
  /* border-radius: 2px; */
  background: #000000 0% 0% no-repeat padding-box;
  font: normal normal normal 12px/13px Poppins !important;
  border: 1px solid #282932 !important;
  letter-spacing: 0px;
  border-radius: 10px !important;
  font-family: 'Poppins'!important;
  font-size: 10px !important;
  height: 20px !important;
  opacity: 1;
}

.sensitivity > span, .default-toppings > span {
  color: #ffffff !important;
  /* border-radius: 2px; */
  text-align: left;
  letter-spacing: 0.19px;
  font: normal normal normal 10px/13px Poppins !important;
  font-family: 'Poppins';
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  height: 13px;
  opacity: 1;
}

.product-size-grid, .product-base-grid {
  padding-left: 0px !important;
  padding-right: 0px !important;
  font: normal normal medium 14px/23px 'Poppins-Medium';
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
 
}

.product-size-grid svg, .product-base-grid svg {
  color: #FFFFFF !important;
 
}

.product-size-grid > div > div,  .product-base-grid > div > div {
  background-color: #000 !important;
  color: #fff !important;
  border-radius: 0px !important;
  font-family: 'Poppins-Medium' !important;
  font-size: 14px;
  line-height: 23px;
  border: 1px solid #FFFFFF96 !important;
}

.time-slot-field div, .half-n-half-field div {
  background-color: #000 !important;
  color: #fff !important;
  border-radius: 0px !important;
}


/* .product-btn-non-selected, .product-btn-non-selected:hover {
  margin-right:"5px" !important;
  width:"240px" !important;
  height:"85px" !important;
  background-color:"#F6B327"  !important;
  border-radius: "12px" !important;
   font-size: "18px" !important;
   font-family: "Poppins" !important;
   color: "#000000" !important;
 } */

#product-modal .MuiDialog-paper.MuiDialog-paperScrollBody, #popup-banner-dialog .MuiDialog-paper.MuiDialog-paperScrollBody {
  background: #000000 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 33px #ffffff; */
  padding: 0px;
}

#cart-offers-modal .MuiDialog-paper.MuiDialog-paperScrollBody, #checkout-modal .MuiDialog-paper.MuiDialog-paperScrollBody {
  background: #000000 0% 0% no-repeat padding-box;
  padding: 0px;
  color: #ffffff;
  border-radius: 0px !important;
}
#cart-offers-modal .MuiDialog-paper.MuiDialog-paperScrollBody {
  width: 500px;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}
.MuiMenuItem-root.select-size-item, .MuiMenuItem-root.select-size-item:hover {
  background-color: #000;
  color: #ffffff;
} 
 .MuiMenu-list {
  background-color: #000;
  color: #ffffff;
} 

.product-quantity {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 3px solid #D6D6D6;
  border-radius: 10px;
  color: #000000;
  font-size: 32px !important;
}

.MuiDialog-paperScrollBody[aria-labelledby="reorder-store-dialog-title"] {
  display: block !important;
}

.product-quantity-options {
  font-weight: 800 !important;
  color: #000000 !important;
  font-size: 32px !important;
}

.product-price-action {
  font-weight: 600 !important;
  font-size: 32px !important;
}

  .half-circle {
    /* background-color: lightseagreen; */
    display: inline-block;
    margin: 5px;
    color: white;
    text-align: left;
    vertical-align: top;
    overflow: hidden;
    font-size: 15px;
    white-space: nowrap;
    font-weight: 700;
    border: 1px solid #F9FAFB;
    cursor: pointer;
  }

  .half-circle-background-color {
    background-color: #F6B327;
    border: 1px solid #F6B327 ;
    font-weight: 700;
  }

  .half-circle.horizontal {
    width: 580px;
    height: 290px;
    line-height: 290px;
  }
  .half-circle.horizontal.top {
    border-radius: 50% 50% 0 0/100% 100% 0 0;
  }
  .half-circle.horizontal.bottom {
    border-radius: 0 0 50% 50%/0 0 100% 100%;
  }
  .half-circle.vertical {
    width: 100px;
    height: 200px;
    line-height: 200px;
    text-orientation: upright;
  }
  .cart-half-circle {
    /* background-color: lightseagreen; */
    display: inline-block;
    margin: 2px;
    color: white;
    text-align: left;
    vertical-align: top;
    overflow: hidden;
    font-size: 15px;
    white-space: nowrap;
    font-weight: 700;
    border: 1px solid #F9FAFB;
    cursor: pointer;
  }

  .cart-half-circle.vertical {
    width: 40px;
    height: 80px;
    line-height: 200px;
    text-orientation: upright;
  }

  .cart-half-circle.vertical.right,.half-circle.vertical.right {
    border-radius: 0 100% 100% 0/0 50% 50% 0;
  }
  .cart-half-circle.vertical.left, .half-circle.vertical.left {
    border-radius: 100% 0 0 100%/50% 0 0 50%;
  }
  
  .rotate {
    display: inline-block;
    height: 100%;
    transform: rotate(90deg);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    text-align: left;
  }

  .half-n-half-selected {
    background-color: #F6B327 !important;
    color: #000000 !important;
    margin-bottom: 0px;
  }

  .half-n-half-non-selected {
    color: #ffffff !important;
    background-color: #000000 !important;
    margin-bottom: 0px;
  }

  .half-n-half-heading {
    text-align: left;
    font: normal normal 600 12px/14px Poppins;
    letter-spacing: 0.29px;
    color: #000000;
    opacity: 1;
    font-size: 12px !important;
    font-family: 'Poppins' !important;
  }

  .hnh-product-btn-selected {
    margin-right: 8px !important;
    margin-bottom: 5px !important;
    min-width: 88px !important;
    height: 33px !important;
    border: 1px solid #D6D6D6 !important;
    background-color: #F6B327 !important;
    border-radius: 6px !important;
    font-size: 11px !important;
    font-family: "Poppins";
    color: #000000 !important;
    font-weight: 600 !important;

  }

  .hnh-product-selected-part {
    background-color: #F6B327 !important;
    color: #000000 !important;
  }

  .hnh-product-btn-selected:hover { 
    background-color: #F6B327;
    border: 1px solid #D6D6D6;
  }

  .hnh-product-btn-non-selected {
    margin-right: 8px !important;
    margin-bottom: 5px !important;
    min-width: 88px !important;
    height: 33px !important;
    border: 1px solid #D6D6D6 !important;
    background-color: #FFFFFF !important;
    border-radius: 6px !important;
    font-size: 11px !important;
    font-family: "Poppins";
    color: #000000 !important;
    font-weight: 500 !important;

  }

  .hnh-product-btn-non-selected:hover { 
    background-color: #FFFFFF;
    border: 1px solid #D6D6D6;
  }

  .hnh-product-quantity {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #777777;
    border-radius: 6px;
    color: #000000;
    opacity: 1;
    font-size: 16px !important;
  }
  
  .hnh-product-quantity-options {
    font-weight: 600 !important;
    color: #000000 !important;
    font-size: 16px !important;
    text-align: left;
    font: normal normal 600 16px/26px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    font-family: Poppins;
  }
  
  .hnh-product-price-action {
    font-weight: 600 !important;
    font-size: 15px !important;
    text-align: left;
    font: normal normal 600 15px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-family: Poppins;
  }

  .hnh-price-border {
    min-height: 55px !important;
  }

  .cart-add {
    /* background: transparent 0% 0% no-repeat padding-box; */
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
  }

  .cart-area {
    background: #121619 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F6B327;
    border-radius: 10px;
    opacity: 1;
  }

  .cart-item-name {
    text-align: left;
    font: normal normal bold 18px/14px Poppins;
    letter-spacing: 0.43px;
    color: #F6B327;
    text-transform: capitalize;
    opacity: 1;
    font-size: 18px !important;
    font-family: 'Poppins' !important;
    font-weight: bold !important;
  }

  .cart-deal-item-name, .cart-hnh-item-name  {
    text-align: left;
    font: normal normal bold 18px/14px Poppins;
    letter-spacing: 0.43px;
    color: #F6B327;
    text-transform: capitalize;
    opacity: 1;
    font-size: 14px !important;
    font-family: 'Poppins' !important;
    font-weight: bold !important;
  }

  .cart-item-topping {
    color: #5C5C5C !important;
    /* border-radius: 2px; */
    background: #F5F5F5 0% 0% no-repeat padding-box;
    background-color: #F5F5F5 !important;
    border-radius: 8px;
    opacity: 1;
    border: 1px solid #282932 !important;
    letter-spacing: 0px;
    font-family: 'Poppins'!important;
    font-size: 10px !important;
    height: 20px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    opacity: 1;
  }
  
  .cart-item-topping > span {
    text-align: left;
    font: normal normal normal 10px/14px Nunito;
    letter-spacing: 0.24px;
    opacity: 1;
    color: #5C5C5C !important;
    text-align: left;
    letter-spacing: 0.19px;
    font-family: 'Nunito' !important;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
    height: 13px;
    opacity: 1;
  }

  .cart-product-quantity {
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    border: 0.1px solid #F6B327;
    border-radius: 6px;
    color: #000000;
    opacity: 1;
    font-size: 14px !important;
  }
  
  .cart-product-quantity-options {
    font-weight: 900 !important;
    color: #F6B327 !important;
    font-size: 17px !important;
    text-align: left;
    font: normal normal 600 14px/26px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    font-family: Poppins;
  }

  .product-delivery-status, .product-delivery-status .MuiFormControlLabel-label {
    text-align: left;
    font: normal normal medium 16px/26px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 16px !important;
    font-family: Poppins;
  }

  .pizza-club-theme, #order-date-picker .MuiInputBase-sizeSmall, .MuiCalendarPicker-root.css-169iwlq-MuiCalendarPicker-root, .MuiCalendarPicker-root.css-169iwlq-MuiCalendarPicker-root .MuiPickersDay-root,
  #order-address .MuiAutocomplete-root .MuiOutlinedInput-root {
    color: #FFFFFF !important;
    background-color: #000 !important;

  }
  #order-date-picker .MuiInputBase-sizeSmal , #order-address .MuiAutocomplete-root .MuiOutlinedInput-root {
    border-radius: 0px !important;
  }

  #order-date-picker .MuiPickersDay-root.Mui-selected {
    border: 1px solid #FFFFFF !important;
  }

  .css-fu5e6n-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
    border: none !important;
}

.delivery-instructions {
  text-align: left;
  font-size: 14px !important;
  line-height: 16px;
  letter-spacing: 0.24px;
  color: #FFFFFF;
  opacity: 1;
  font-family: Poppins !important;
  padding: 5px;
  width: 100% !important;
}

.selectBtn {
  background-color: #393939 !important;
  z-index: 1;
  color: #FFFFFF !important;
}
.cart-text-field {
  padding: 5px ;
}

.cart-text-field .MuiOutlinedInput-root {
  color: #FFFFFF !important;
  background-color: #000 !important;
  border-radius: 0px !important;
  font-size: 15px !important;
}

.cart-sign-up-text {
  text-align: center;
  font: normal normal 600 16px/22px Nunito;
  letter-spacing: 0px;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 17px;
  font-family: 'Nunito';
}

.cart-grand-total-label {
  font: normal normal 600 20px/33px Poppins;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: 'Poppins' !important;
  display: "block"

}

.cart-text-field .MuiFormHelperText-root {
  color: red;
}

.cart-actions {
  font-size: 12px !important;
  float: right;
  word-spacing: 5px;
  padding-right: 5px;
  text-transform: none;
  font-weight: 500 !important;
}

.cart-actions-item {
  background-color: #F6B327 !important;
  padding: 3px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 18px !important;
  color: #000 !important;
  font-size: 12px !important;
  cursor: pointer !important;
}

.category-bar:hover .scroll-left-arrow, .category-bar:hover .scroll-right-arrow, .subcategory-bar:hover .scroll-left-arrow, .subcategory-bar:hover .scroll-right-arrow {
  display: block !important;
}

.category-bar .scroll-left-arrow {
  display: none !important;
  position: absolute !important;
  top: 1.2rem;
  left: 1rem;
  font-size: 30px !important;
  z-index: 222;
}


.category-bar .scroll-right-arrow {
  display: none !important;
  position: absolute !important;
  top: 1.2rem;
  right: 1rem;
  z-index: 222;
  font-size: 30px !important;
}

.subcategory-bar .scroll-left-arrow {
  display: none !important;
  position: absolute !important;
  top: 0.5rem;
  left: 1rem;
  font-size: 30px !important;
  z-index: 222;
}


.subcategory-bar .scroll-right-arrow {
  display: none !important;
  position: absolute !important;
  top: 0.5rem;
  right: 1rem;
  z-index: 222;
  font-size: 30px !important;
}


.category-horizontal-scroll, .subcategory-horizontal-scroll {
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  scroll-behavior: smooth; 
}

.discount-coupon {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #DC0000;
  border-radius: 10px;
  opacity: 1;
}

.discount-coupon-header {
  text-align: left;
  font: normal normal bold 18px/27px Nunito;
  font-family: Nunio !important;
  font-weight: bold !important;
  letter-spacing: 0px;
  color: #282932 !important;
  opacity: 1;
}

.discount-coupon-subheader {
  text-align: left;
  font: normal normal medium 12px/14px Nunito;
  font-family: Nunio !important;
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #777777 !important;
  opacity: 1;
  font-family: 'Nunito' !important;
}

.pizza-club .MuiCard-root, .order-details-content {
  background-color: #121619 !important;
  color: #ffffff !important;
  border: 1px solid #121619;
  margin: 10px;

}


.pizza-club .MuiNativeSelect-select, .pizza-club .MuiNativeSelect-select option,.pizza-club .MuiOutlinedInput-root, #mobile-cart-dialog .MuiPaper-root {
  background-color: #121619 !important;
  color: #ffffff !important;
  border: 1px solid #121619;

}

.mobile-cart-dialog-content, #mobile-cart-dialog .MuiDialog-container > .MuiPaper-root[aria-describedby='mobile-cart-dialog-description'], 
#paymark-dialog .MuiDialog-container > .MuiPaper-root[aria-describedby='paymark-dialog-description'],
#paymark-save-card-dialog .MuiDialog-container > .MuiPaper-root[aria-describedby='save-card-dialog-dialog-description'],
.reorder-store-dialog .MuiDialog-container > .MuiPaper-root[aria-describedby='reorder-storel-dialog-description'],
.order-details-modal .MuiDialog-container > .MuiPaper-root[aria-describedby='order-details-description'] {
  overflow-y: unset !important;
}


#paymark-dialog .MuiDialog-container > .MuiPaper-root[aria-describedby='paymark-dialog-description'],
#paymark-save-card-dialog .MuiDialog-container > .MuiPaper-root[aria-describedby='save-card-dialog-dialog-description'],
.reorder-store-dialog .MuiDialog-container > .MuiPaper-root[aria-describedby='reorder-storel-dialog-description'],
.order-details-modal .MuiDialog-container > .MuiPaper-root[aria-describedby='order-details-description']{
  max-width:  unset !important;
}

#paymark-dialog .MuiDialog-container > .MuiPaper-root[aria-describedby='paymark-dialog-description'],
#paymark-save-card-dialog .MuiDialog-container > .MuiPaper-root[aria-describedby='save-card-dialog-dialog-description']{
  background-color: #ffffff;
}


button.pizza-club-btn-disabled {
  background-color: gray !important;
  color: #fff !important;
}

.track-order {
  background: #121619 0% 0% no-repeat padding-box !important;
  border: 1px solid #F6B327 !important;
  border-radius: 19px;
  opacity: 1;
}

.pizza-club .MuiTab-root.Mui-selected  {
  color: #F6B327 !important;
}

.order-status-step .MuiStepLabel-label.Mui-active  {
  color: #919EAB !important
}

.order-status-step .Mui-active  {
  color: #637381 !important
}

.order-status-step-disable .MuiSvgIcon-root,.order-status-step-disable .MuiStepLabel-label {
  color: #919EAB !important;
}

.order-status-step.Mui-completed .MuiStepLabel-label.Mui-completed {
  color: white !important
}

.order-status .MuiStepLabel-label {
  font-weight: 800 !important;
  font-size: 16px !important;
}

.red-background-color {
  background-color: red !important;
}

.header-option, .footer-option {
  text-align: left;
  font: normal normal normal 16px/26px Poppins;
  letter-spacing: 0px;
  color: #ffffff !important;
  font-size: 16px !important;
}

.header-pipe {
  margin-left: 20px  !important;
  margin-right: 20px  !important;
}

.pizza-club-load-btn .MuiCircularProgress-root, .pay-now-btn .MuiCircularProgress-root .MuiCircularProgress-svg, .product-add-cart-btn .MuiCircularProgress-root .MuiCircularProgress-svg {
  color: #000000 !important;
}

.pay-now-btn .MuiCircularProgress-root .MuiCircularProgress-svg {
  color: #000000 !important;
}

.no-location-error-msg {
  text-align: left;
  font: normal normal normal 12px/19px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 12px !important;
}

.stores-btn {
  font: normal normal 600 24px/40px Poppins;
  font-size: 24px !important;
}

.store-name {
  font: normal normal bold 28px/46px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 28px !important;
}

.store-details {
  font: normal normal normal 20px/49px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 20px !important;
  text-decoration: none;
}

.store-details-item {
  padding-left: 6px !important;
}

.selected-store-name, .selected-deal-name {
  font: normal normal bold 23px/38px Poppins;
  letter-spacing: 0px;
  font-size: 23px !important;
}

.selected-store-details {
  font: normal normal normal 14px/37px Poppins;
  font-size: 14px !important;
}
.contact-store-details {
  font: normal normal normal 14px/37px Poppins;
  font-size: 16px !important;
}

#feedback-message-field  .MuiInputBase-multiline { 
  background: #000;
}


.selected-change-store-btn {
  font: normal normal bold 20px/33px Poppins;
  font-size: 20px !important;
}

.sub-category-bottom-border {
  border-bottom-style: solid;
  border-bottom-color: rgba(145, 158, 171, 0.24);
  border-bottom-width: thin;
  margin-bottom: 40px;
  padding-right: 10px;
  width: 100% !important;
  /* padding-top: 24px; */
}

.product-amount {
  font: normal normal medium 20px/33px Poppins;
  letter-spacing: 0px;
  color: #F6B327;
  opacity: 1;
  font-size: 18px !important;
  font-family: 'Poppins-Medium' !important;
}

.product-add-cart-btn {
  font: normal normal 600 16px/26px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 16px !important;
}

.mobile-cart {
  padding-left: 0px !important;
}

#site-banner button[aria-label="Previous"], #site-banner button[aria-label="Next"], #store-banner button, .cart-banner button {
  display: none;
}

#site-banner:hover button[aria-label="Previous"], #site-banner:hover button[aria-label="Next"], #store-banner:hover button, .cart-banner:hover button {
  display: inline-flex;
}

.cart-banner button {
  display: none;
} 

.cart-banner:hover button {
  display: inline-flex;
}

.item-size-base-name {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.near-store-btn:hover, .all-stores-btn:hover {
  background-color: none !important;
}
.stores-btn.active, .stores-btn.active:hover {
  background-color: #F6B327;
  z-index:  1;
  color: #000 !important;
}

.stores-btn.inactive, .stores-btn.inactive:hover {
  background-color: rgb(112, 112, 112) !important;
  color: rgb(255, 255, 255) !important;
}


.store-btn.active {
  background-color: #F6B327;
  z-index:  1;
  color: #000 !important;
}

.sticky-container {
  /* padding-top: 10px; */
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 0;
  z-index: 222;
}

.sticky-cart-container {
  position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    top: 0;
    z-index: 22;
    padding-top: 8px !important;
    height: 100vh;
    overflow: auto;
}
.sticky-cart-container::-webkit-scrollbar {
  width: 0em;
}

.sticky-cart-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.store-card-icon {
  width: 33px;
  height: 33px;
  max-width: unset;
  display: inline-block;
}

.store-details-icon {
  width: 20px;
  height: 20px;
  max-width: unset;
}


.store-btn-text-bottom-position {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.store-select-btn {
  width: 234px;
}

.store-select-button {
  font: normal normal 600 24px/40px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 24px !important;
  height: 40px !important;
  border-radius: 24px !important;
}

.category-name {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 16px !important;
  line-height: 30px;
  font-family: 'Poppins-Medium' !important;
}

.container {
  padding-left: 144px;
  padding-right: 144px;
}

.cursor-pointer{
  cursor: pointer;
}

.image-grayscale {
  filter: grayscale(1);
  opacity: .4;
}


.store-address {
  margin-right: 10px;
}

.header-option:hover, .footer-option:hover {
  text-decoration: none !important;
} 

#social-icon {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.active-tab {
  color: #F6B327 !important;
  border-bottom: 2px solid #fda92d;
}

.thin-divider {
  border-style: solid !important;
  border-color: slategray !important;
  border-bottom-width: 1px !important;
  opacity: 1;
}

.thin-border {
  border: 1px solid slategray !important;
}

.footer-description {
  text-align: left;
  font: normal normal normal 14px/23px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 14px !important;
  line-height: 23px !important;
}

.footer-heading {
  text-align: left;
  font: normal normal bold 18px/29px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 18px !important;
  font-family: 'Poppins-Bold' !important;

}

.quick-link {
  text-align: left;
  font: normal normal medium 14px/36px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 14px !important;
  font-family: 'Poppins-Medium' !important;
  line-height: 36px !important;
}

.landing-scroll-btn {
  position: fixed !important;
  bottom:45px;
  right: 0;
  margin-right: 10px !important;
  z-index: 3333;
}

.scroll-top-btn {
  position: fixed !important;
  bottom: 20px;
  right: 0;
  margin-right: 10px !important;
  z-index: 3333;
  cursor: pointer;
}

.cart-container {
  padding-top: 150px !important;
}

.hidden {
  display: none !important;
}
.menu-product-name {
  text-align: left;
  font: normal normal bold 18px/29px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  font-size: 18px !important;
}

.empty-cart-header {
  text-align: left;
  font: normal normal 600 30px/23px Poppins !important;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.empty-cart-subheader {
  text-align: left;
  font: normal normal normal 14px/23px Poppins !important;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.product-details-dialog > .MuiDialog-container.MuiDialog-scrollBody {
  text-align: left !important;
}

.product-details-dialog > .MuiDialog-container.MuiDialog-scrollBody > .MuiDialog-paperScrollBody {
  border-radius: 0px;
  margin: 0;
}

.cart-offer-text {
  text-align: left;
  font: normal normal bold 16px/5px Nunito;
  letter-spacing: 0px;
  color: #282932;
  opacity: 1;
  font-size: 16px;
  font-family: Nunito;
}

.cart-offer {
  display: inline-block;
}

.discount-icon {
  padding: 5px !important;
  /* padding-left: 0px !important; */
  /* padding-right: 0px !important; */
}

.discount-icon svg {
  margin-bottom: 28px;
}

#order-date-picker .MuiOutlinedInput-root {
  border-radius: 0px !important;
}

.save-order {
  text-align: right;
  font: normal normal medium 12px/14px Nunito;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 12px !important;
  font-family: Nunito !important;
}

.checkout-btn-text {
  display: inline-block;
  float: left;
}

.card-grand-total{
  display: inline-block;
  float: right;
}

.hnh-product-details .MuiDialog-paper.MuiDialog-paperScrollBody {
  max-width: 1000px;
}

.border-top {
  border-top-style: solid !important;
  border-top-color: slategray !important;
  border-top-width: 1px !important;
}

.border-bottom {
  border-bottom-style: solid !important;
  border-bottom-color: slategray !important;
  border-bottom-width: 1px !important;
}

.half-n-half-field .MuiOutlinedInput-root.MuiInputBase-root, .half-n-half-field .MuiOutlinedInput-root.MuiInputSize-root {
  padding-left: 24px;
}

.hnh-field {
  font: normal normal normal 14px/23px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 14px !important;
  font-family: Poppins !important;
}

.delivery-instructions::placeholder {
  color: '#ffffff' !important;
}

#order-details-modal .MuiDialog-paperScrollBody {
  border-radius: 0px !important;
  height: auto !important;
}

.max {
  transform: rotate(45deg);
  display: block;
  position: absolute;
  top: 16px;
  right: 3px;
  font-size: 20px;
  letter-spacing: 2px;
}

.max-banner {
  position: absolute;
  right: -1px;
  top: -2px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 82px;
  height: 86px;
}

.landing-banner {
  opacity: 0.9;
}

.pz-input-field {
  color: #ffffff !important;
  background-color: #000000 !important;
}

.store-description-item {
  width:90% !important;
  display:inline-block !important;
  padding-left:10px !important;
}

#locations-box {
  padding-right: 10px !important;
}

.add-to-cart-snackbar > #notistack-snackbar {
  font-size: 30px;
}

.add-to-cart-snackbar > #notistack-snackbar > span {
  width: 70px !important;
  height: 70px !important;
}

.add-to-cart-snackbar > #notistack-snackbar > span > svg {
  width: 60px !important;
  height: 60px !important;
}

.left-navbar ul:nth-child(2) {
  position: fixed;
  bottom: 0;
}

.left-navbar ul:nth-child(2) a {
  border-bottom: unset;
}

.left-navbar ul:nth-child(2) a .MuiListItemIcon-root{
  margin-right: 0px;
}


[role='dialog'].MuiPopperUnstyled-root, [role="presentation"].MuiPopover-root.MuiMenu-root.MuiModal-root {
  z-index: 24445;
}

.mobile-track-delivery-address {
  text-align: left;
  font: normal normal normal 12px/19px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}



/* .MuiDialog-root.product-details-dialog {
  bottom: unset !important;
} */

/* .product-details-dialog .MuiPaper-root {
  vertical-align: unset !important;
} */

@media only screen and (max-width: 1024px) {
  
}

@media only screen and (max-width: 600px) {

  #cart-offers-modal .MuiDialog-paper.MuiDialog-paperScrollBody {
    background-color: #212B36 !important;
  }

  .cart-user-details .sign-in-btn {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .cart-user-details .contact-details {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .product-details-dialog .MuiPaper-root {
    vertical-align: middle !important;
  }

  .mobile-customize-product-options {
    height: 60px !important;
  }
  .mobile-customize-product-price-action {
    padding: 12px !important;
  }
  .mobile-customize-product-price {
    padding-top: 6px !important;
  }

  .scroll-top-btn {
    bottom: 100px !important;
  }

  .page-name {
    font: normal normal 600 16px/26px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
  }

  .mobile-reorder-confirmation-modal {
    width: 100%;
  }
  .max {
    top: 16px;
    right: 3px;
    font-size: 15px;
}

  .max-banner {
    width: 65px;
    height: 65px;
}

  .store-description-item {
    width:85% !important;
    margin-bottom: 7px !important;
  }

  .add-to-cart-snackbar > #notistack-snackbar {
    font-size: 20px;
  }
  
  .add-to-cart-snackbar > #notistack-snackbar > span {
    width: 50px !important;
    height: 50px !important;
  }
  
  .add-to-cart-snackbar > #notistack-snackbar > span > svg {
    width: 40px !important;
    height: 40px !important;
  }

  .product-details-dialog > .MuiDialog-container.MuiDialog-scrollBody {
    text-align: center !important;
  }
  
  .product-details-dialog > .MuiDialog-container.MuiDialog-scrollBody > .MuiDialog-paperScrollBody {
    border-radius: unset;
    margin: unset;
  }


  .store-details-item {
    padding-left: 24px !important;
  }

  .container {
    padding-left: 8px;
    padding-right: 8px;
  }

  .store-select-button {
    font: normal normal 600 14px/23px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-size: 14px !important;
    height: 37px !important;
    border-radius: 24px !important;
  }

  .store-btn-text-bottom-position {
    position: absolute;
    bottom: 16px;
    width: 95%;
    right: unset;
  }


  .store-card-icon {
    width: 30px;
    height: 30px;
    max-width: unset;
  }

  .mobile-main-heading {
    font-size: 20px !important;
  }

  .pizza-club .MuiCard-root, .order-details-content {
    margin: 0px !important;
  }

  .pizza-club .MuiCard-root.deal-offers {
    margin: 12px !important;
  }

  .mobile-checkout-dialog-heading {
    font-size: 14px !important;
  }

  .mobile-checkout-dialog-content {
    font-size: 12px !important;
  }

  .hnh-grid-container {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .hnh-container {
    padding-left: 0px !important;
    padding-right: 0px !important;      
  }

  .customise-product .product-customize {
    font: normal normal medium 12px/9px Poppins;
    letter-spacing: 0.29px;
    font-size: 12px;
  }

  .customise-product .product-quantity {
    height: 34px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #777777;
    border-radius: 6px;
  }

  .customise-product .product-quantity-options {
    font: normal normal 600 13px/21px Poppins;
    letter-spacing: 0px;
    font-size: 13px !important;
  }

  .customise-product .product-price-action {
    font-size: 16px !important;
    padding-top: 4px;
  }

  .customise-product .product-price-add-btn {
    font-size: 12px !important;
  }

  .customise-product .select-base-label, .customise-product .select-size-label, .customise-product .select-topping-label{
    font: normal normal 600 14px/14px Poppins;
    letter-spacing: 0.34px;
  }

  .customise-product .product-btn {
    font: normal normal normal 8px/6px Poppins;
    letter-spacing: 0.19px;
    border-radius: 6px !important;
  }
  .customise-product .product-description {
    font: normal normal normal 12px/19px Poppins;
    letter-spacing: 0.29px;
    font-size: 12px !important;
  }


  .customise-product .product-name {
    text-align: center;
    font: normal normal bold 15px/23px Poppins;
    font-size: 15px !important;
    font-weight: 700 !important;
  }

  .mobile-cart-bottom-btn {
    position: fixed;
    text-align: center;
    bottom: 20px;
    font: normal normal 600 20px/50px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-size: 20px !important;
    font-weight: 600 !important;
    background: #F6B327 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 9px;
    height: 50px;
    margin: 5px !important;
    width: 96.5%;
    justify-content: center;
  }


  .menu-sticky-btn {
    position: -webkit-sticky !important; /* Safari & IE */
    position: -moz-sticky !important;
    position: -ms-sticky !important;
    position: -o-sticky !important;
    position: sticky !important;
    top: 0;
    padding-bottom: 10px;
    z-index: 22;
    height: 60px;
    background-color: black;
  }
  .mobile-menu-name {
    display: block !important;
    text-align: center;
    font: normal normal bold 20px/75px Poppins;
    letter-spacing: 0px;
    font-size: 20px !important;
    text-transform: uppercase;
    opacity: 1;
    justify-content: left !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .cart-grand-total-label {
    font-size: 17px !important;
}

  .product-delivery-status, .product-delivery-status .MuiFormControlLabel-label {
    font-size: 13px !important;
  }

  .mobile-cart-item-amount {
    font-size: 17px !important;
  }

  .cart-product-quantity {
    font-size: 10px !important;
  }

  .cart-item-topping > span {
    font-size: 9px !important;
  }



  .cart-actions-item {
    font-size: 10px !important;
  }

  .cart-item-name {
    font-size: 15px !important;
  }
  .cart-deal-item-name, .cart-hnh-item-name  {
    font-size: 12px !important;
  }

  .mobile-cart {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .product-topping-name, .product-sensitivity {
    font-size: 10px !important;
    font: normal normal normal 8px/13px Poppins !important;
  }


  .product-description {
    font-size: 9px !important;
  }

  .product-sub-category-name {
    font-size: 16px !important;
  }

  .products-container {
    margin-left: -12px !important;
  }

  .mobile-category {
    text-align: center;
    font: normal normal bold 20px/75px Poppins;
    letter-spacing: 0px;
    font-size: 20px !important;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
    justify-content: center !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .selected-store-info .MuiPaper-root {
    height: unset !important;
  }

  .selected-change-store-btn {
    font: normal normal 600 14px/23px Poppins;
    font-size: 14px !important;
    width: 100% !important;
  }

  .selected-store-area {
    padding-left: 30px !important;;
  }

  .selected-store-details {
    font: normal normal normal 12px/37px Poppins;
    font-size: 12px !important;
  }

  .contact-store-details {
    font: normal normal normal 12px/37px Poppins;
    font-size: 14px !important;
  }

  .selected-store-name {
    font: normal normal bold 14px/23px Poppins;
    font-size: 14px !important;
  } 

  .store-select-btn {
    float: none !important;
  }

  .store-details {
    font: normal normal normal 12px/39px Poppins;
    font-size: 12px !important;
  }

  .store-name {
    font: normal normal bold 14px/23px Poppins;
    font-size: 14px !important;
  }

  .mobile-store-tabs .MuiTabs-flexContainer {
    justify-content: center;
  }
  .stores-btn {
    font: normal normal 600 12px/19px Poppins;
    font-size: 12px !important;
  }

  .store-type-heading {
    font: normal normal 600 12px/19px Poppins;
    font-size: 16px !important;
  }

  .whats-new-text, .previous-selected-store {
    font: normal normal bold 16px/26px Poppins;
    font-size: 16px !important;
  }
    
.site-announcement {
  font-size: 11px !important;
}

.pz-input {
  font-size: 16px !important;
  padding: 10px !important;
  /* color:#000000 !important;  */
}
 .landing-page-main-text {
   font-size: 16px !important;
 }
 .landing-page-root {
   padding-top : 30px !important;
   padding-bottom : 30px !important;
   height: 200px !important;
 }
 #search-store{
  padding-top: 10px;
 }

 #search-store > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root {
  padding: 0px;
 }

 #search-store > .MuiAutocomplete-root > .MuiFormControl-root {
  border-radius: 5px;
 }

 .use-my-location {
  font-size: 10px !important;
 }
 .no-location-error-msg {
  font: normal normal normal 8px/12px Poppins;
  font-size: 8px   !important;
 }

 #locations-box {
   font-size: 16px;
   /* padding-top: 0px; */
   /* padding-bottom: 0px;; */
 }
 .store-search-icon {
   margin-right: 5px !important;
   font-size: 12px !important;
 }
}

@media only screen and (max-width: 360px) {

  .selected-store-area {
    padding-left: 0px !important;;
  }

  .order-details-dialog-heading {
    font-size: 16px !important;
  }

  .order-details-dialog-description {
    font-size: 14px !important;
  }

  .cart-user-details .sign-in-btn {
    padding-left: 33px !important;
    padding-right: 33px !important;
    font-size: 12px !important;
    margin-right: 36px;
  }

  .cart-user-details .contact-details {
    padding-left: 29px !important;
    padding-right: 30px !important;
    font-size: 12px !important;
    margin-left: -65.8px !important;
  }

  .cart-area  .product-image {
    height: 100px !important;
    width: 100px !important;
  }

  .product-quantity-btn-group {
    padding-left: 5px !important;
    padding-right: 0px !important;
  }

  .mobile-customize-product-price-action {
    padding-right: 5px !important;
    padding-left: 0px !important;
  }

  .mobile-customize-product-price {
    padding-right: 20px !important;
  }
 
  .empty-cart-header {
    font: normal normal 600 20px/23px Poppins !important;
    padding-left: 24px !important;
  }

  .checkout-btn-text, .card-grand-total {
    font-size: 18px !important;
    margin-top: 3px;
  }
  
  #checkout-dialog-title {
    font-size: 18px !important;
  }

  .order-products-details {
    font-size: 14px !important ;
  }

  .payment-options-content{
    padding:24px 10px !important;
  }
  .checkout-actions{
    padding:24px 5px !important;
  }
  .checkout-actions button {
    font-size: 12px !important;
  }

  .checkout-actions .edit-order-btn, .checkout-actions .confirm-btn {
    margin-left: 8px !important;
  }

  .cart-offer-text {
    font-size: 11px !important;
  }

  .discount-icon svg {
    margin-bottom: 12px;
}

.caret-right-icon.discount-icon svg {
  margin-top: 5px;
}

  .discount-coupon-subheader {
    font-size: 9px !important;
  }

  .payment-options-description {
    margin-left: 10px !important;
  }
  .payment-options-header {
    padding-left: 10px !important;
  }

  .payment-type-label-option {
    padding-right: 0px !important;
  }

  .payment-type-label-option > label {
    margin-right: 0px !important;
  }
  .order-status .MuiStepLabel-label, .track-order-details {
    font-size: 14px !important;
  }

  }
